import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { theme } from '../styles/themes';
import {
  BackgroundBlock,
  GridBlock,
  AnnouncementBlock,
  TextBlock,
} from '../components/block-components/exports';
import { ImageContainer, Image } from '../components/image-components/exports';

const LandingPage = () => (
  <Layout>
    <SEO title="Home" />
    <AnnouncementBlock>
      <TextBlock pColor={theme.color.grey} textAlign="center">
        <h2>This Is An Announcement</h2>
        <p>Your Announcement Goes Here.</p>
        <p>Watch out for the Hipster Ipsum below.</p>
      </TextBlock>
    </AnnouncementBlock>
    <BackgroundBlock>
      <GridBlock imagePosition="right">
        <ImageContainer>
          <Image />
        </ImageContainer>
        <TextBlock color="white">
          <h2>Part One</h2>
          <p>
            Succulents vegan disrupt, skateboard blog meditation bespoke kogi
            brunch yr taxidermy hammock. Chillwave man braid dreamcatcher man
            bun trust fund. Pinterest subway tile ugh, pour-over leggings poke
            intelligentsia.
          </p>
        </TextBlock>
      </GridBlock>
    </BackgroundBlock>
    <BackgroundBlock background="white" paddingTop="4vw">
      <TextBlock maxWidth="550px" pColor={theme.color.grey} textAlign="center">
        <h2>Part Two</h2>
        <p>
          Put a bird on it chillwave readymade synth try-hard ennui tousled
          green juice vexillologist tattooed.
        </p>
      </TextBlock>
      <GridBlock imagePosition="right" paddingTop="0">
        <ImageContainer>
          <Image />
        </ImageContainer>
        <TextBlock pColor={theme.color.grey}>
          <h2>Part 2A</h2>
          <p>
            Unicorn pop-up brunch, XOXO banh mi venmo trust fund skateboard meh
            la croix heirloom. Ramps whatever actually woke literally
            fingerstache butcher, coloring book umami plaid ethical. Waistcoat
            squid knausgaard kogi.
          </p>
        </TextBlock>
      </GridBlock>
      <GridBlock imagePosition="left" paddingTop="1rem">
        <ImageContainer>
          <Image />
        </ImageContainer>
        <TextBlock pColor={theme.color.grey}>
          <h2>Part 2B</h2>
          <p>
            Dreamcatcher wolf sriracha organic put a bird on it. Beard
            meditation portland sartorial irony. Kickstarter meh tumeric 90's
            mustache hoodie normcore four dollar toast wayfarers drinking
            vinegar ethical semiotics franzen.
          </p>
        </TextBlock>
      </GridBlock>
      <GridBlock imagePosition="right" paddingTop="1rem">
        <ImageContainer>
          <Image />
        </ImageContainer>
        <TextBlock color={theme.color.grey} h2Color={theme.color.black}>
          <h2>Part 2C</h2>
          <>
            Chillwave locavore celiac direct trade:
            <ul>
              <li>Flannel XOXO hoodie</li>
              <li>Subway tile franzen seitan cliche</li>
              <li>
                Seitan everyday carry actually, man bun hell of VHS chicharrones
                etsy PBR&B offal intelligentsia cloud bread
              </li>
              <li>
                90's tbh meh hella, cold-pressed lumbersexual la croix pickled
                pork belly offal leggings air plant
              </li>
            </ul>
            Brunch tofu actually migas coloring book normcore live-edge freegan
            thundercats poutine pour-over.
          </>
        </TextBlock>
      </GridBlock>
    </BackgroundBlock>
  </Layout>
);

export default LandingPage;
