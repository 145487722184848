import styled from 'styled-components';
import PropTypes from 'prop-types';

const ImageContainer = styled.div`
  width: 100%;
  min-width: ${props => props.minWidth};
  max-width: ${props => props.maxWidth};
`;

ImageContainer.propTypes = {
  minWidth: PropTypes.string,
  maxWidth: PropTypes.string,
};

ImageContainer.defaultProps = {
  minWidth: '300px',
  maxWidth: '600px',
};

export { ImageContainer };
